<template lang="pug">
.lot-tag(v-if="hideLabel" :class="size")
  old-tooltip(:content="tag.title")
    .color(:style="`background-color: ${ tag.color }`" :class="disabled && '_disabled'")

.lot-tag(v-else :class="size")
  .color(:style="`background-color: ${ tag.color }`" :class="disabled && '_disabled'")
  .label(v-if="!hideLabel") {{ tag.title }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { TenderTagI } from "~/stores/search/LotItemInterface";
import OldTooltip from "~/components/ui/tooltip/OldTooltip.vue";

export default defineComponent({
  name: "LotTag",
  components: {
    OldTooltip,
  },
  props: {
    tag: {
      type: Object as PropType<TenderTagI>,
      default: () => ({}),
    },
    size: {
      type: String as PropType<'mini'|'default'>,
      default: 'default',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped lang="scss">
.lot-tag {
  display: flex;
  flex-flow: row;
  gap: 8px;

  &.mini {
    font-size: 12px;
    line-height: 16px;

    .color {
      width: 16px;
      height: 16px;
    }
  }

  &.default {
    font-size: 14px;
    line-height: 20px;

    .color {
      width: 20px;
      height: 20px;
    }
  }

  .color {
    border-radius: 4px;

    &:not(._disabled) {
      cursor: pointer;
    }

    &._disabled {
      background-color: var(--main-color-grey-disabled) !important;
    }
  }
}
</style>
